<template>
  <section class="boardWrap">
    <navi-bar>
      {{$t('front.common.notice')}}
    </navi-bar>

    <div class="msgreadwrap">
      <div class="msgreadhead">
        <h2 class="msgreadname"><em class="accounticon">{{$t('front.boardCategory.' + model.category)}}</em>{{model.title}}</h2>
        <p class="msgreadtime">
          <span class="logo"><img src="@/assets/img/hawaiilogo.png" /></span>
          <span class="time">{{dateFormatForBoard(model.regDate)}}</span>
        </p>
      </div>
      <div class="msgreadconwrap">
        <div class="msgreadcon">
          {{model.content}}
        </div>
      </div>
    </div>

    <div class="btnwrap">
      <a class="all">{{$t('front.board.prev')}}</a>
      <a class="all" @click="goPageByName('noticeList')">{{$t('front.board.list')}}</a>
      <a class="all">{{$t('front.board.next')}}</a>
    </div>

  </section>
</template>

<script>
import NaviBar from '@/components/common/mobile/NaviBar'
import Notice from '@/views/member/cscenter/notice/read'

export default {
  name: 'NoticeReadMobile',
  components: {
    NaviBar
  },
  mixins: [Notice]
}
</script>

<style scoped src="@/styles_mobile/common.css"></style>
<style scoped src="@/styles_mobile/board.css"></style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
